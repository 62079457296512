/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import PropTypes from "prop-types"
import styled from "styled-components"
import get from "lodash.get"

// Icons
import { Security } from "@styled-icons/material/Security"
import { Block } from "@styled-icons/material/Block"

// Components
import Tab from "../../components/atoms/Tab"
import PersonalInfo from "../../components/organisms/PersonalInfo"
import BackIcon from "../../components/atoms/BackIcon"
import CustomModal from "../../components/atoms/CustomModal"

import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  Description,
} from "../../components/atoms/ModalElements"

// Utils
import { withAuthorization } from "../../context/Session"
import { withFirebase } from "../../utils/Firebase"

const AgentDetail = ({ firebase }) => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const [profileForm, setProfileForm] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [currentUser, setCurrentUser] = useState(get(state, ["currentUser"]))

  useEffect(() => {
    const getForms = async () => {
      const profile = await firebase.getOrgAgent(state.orgId, currentUser.id)
      setProfileForm({
        ...profile.data(),
        id: currentUser.id,
        orgId: state.orgId,
        locationList: state.locationList,
        location: state.location,
      })
    }
    if (firebase && Object.keys(currentUser).length > 0) {
      getForms()
    }
  }, [firebase, currentUser, state.orgId])

  const handleUserStatus = async (status) => {
    await firebase.saveAgentData({
      orgId: state.orgId,
      id: currentUser.id,
      data: {
        active: status,
        status: status ? "active" : "inactive",
      },
    })
    setCurrentUser((prevState) => {
      return {
        ...prevState,
        active: status,
        status: status ? "active" : "inactive",
      }
    })
    setIsModalOpen(false)
  }

  const getModalTitle = () => {
    return get(currentUser, ["status"]) === "active"
      ? t("block_user")
      : t("enabled_user")
  }

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          {get(currentUser, ["displayName"], "Back")}
        </Back>
        {get(currentUser, ["status"]) === "inactive" && (
          <Badge onClick={() => setIsModalOpen(true)}>
            <span>
              <Security size="18" />
              {t("enabled_user")}
            </span>
          </Badge>
        )}
        {get(currentUser, ["status"]) === "active" && (
          <Badge isBlock onClick={() => setIsModalOpen(true)}>
            <span>
              <Block size="18" />
              {t("block_user")}
            </span>
          </Badge>
        )}
      </Nav>
      <Container>
        <TabsContainer>
          <Tab
            textKey="users_profile"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
        </TabsContainer>
        {activeTab === 0 && Object.keys(profileForm).length > 0 && (
          <PersonalInfo user={profileForm} isAgent />
        )}
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <Title>{getModalTitle()}</Title>
                <Description>
                  {get(currentUser, ["status"]) === "active"
                    ? t("block_confirmation", {
                        user: get(currentUser, ["displayName"], ""),
                      })
                    : t("verify_confirmation", {
                        user: get(currentUser, ["displayName"], ""),
                      })}
                  {}
                </Description>
                <ButtonsContainer>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleUserStatus(
                        get(currentUser, ["status"]) === "inactive"
                      )
                    }
                  >
                    {t("confirm")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </div>
  )
}

const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  width: ${(props) => (props.isBlock ? "180px" : "160px")};
  height: 42px;
  background: rgba(77, 102, 245, 0.1);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
    }
  }
`

AgentDetail.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const ComposedAgentDetail = compose(
  withFirebase,
  withAuthorization(condition)
)(AgentDetail)
export default ComposedAgentDetail
