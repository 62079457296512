/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import {
  Tooltip,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  Legend,
  BarChart,
  Bar,
  ComposedChart,
  Line,
} from "recharts"
import { SadTear } from "@styled-icons/fa-solid/SadTear"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

// Components
import Nav from "../../atoms/Nav"
import Loading from "../../atoms/Loading"
import CustomSelect from "../../atoms/CustomSelect"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

// Utils
import { withFirebase } from "../../../utils/Firebase"

const timeOptions = [
  {
    label: "7 días",
    value: 7,
  },
  {
    label: "30 días",
    value: 30.41,
  },
  {
    label: "3 meses",
    value: 91.25,
  },
  {
    label: "6 meses",
    value: 182.5,
  },
  {
    label: "1 año",
    value: 365,
  },
]

const OrgStatistics = ({ firebase, doctors, agents, orgId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [stats, setStats] = useState(null)
  const [filterDays, setFilterDays] = useState(7)
  const [isButtonCsvLoading, setButtonCsvLoading] = useState(false)
  const [isButtonPdfLoading, setButtonPdfLoading] = useState(false)
  const [doctorsData, setDoctorsData] = useState(null)
  const [filterByDoctor, setDoctorFilter] = useState("")
  const [orgLocationList, setOrgLocationList] = useState([])
  const [departmentsList, setDepartmentsList] = useState([])
  const [locationFilter, setLocationFilter] = useState("")
  const [departmentFilter, setDepartmentFilter] = useState("")

  const getUserMedicalData = async (email) => {
    try {
      const getUserRecord = await firebase.getUserRecord(email)
      const userData = getUserRecord?.data()

      if (!userData) return null

      const [form, getAgentsByOrganization] = await Promise.all([
        firebase.getMedicalRecords(email),
        firebase.getOrgForm(userData?.organizationId, "agents"),
      ])

      const agentsData = getAgentsByOrganization?.docs?.map((agentsOrg) => ({
        ...agentsOrg?.data(),
      }))

      const findUserOnOrganization = agentsData?.find(
        (user) => user.email === email
      )

      if (!findUserOnOrganization) return null

      if (form.docs.length > 0) {
        return form.docs.map((d) => ({
          id: d.id,
          userEmail: findUserOnOrganization?.email,
          department: findUserOnOrganization?.department,
          location: findUserOnOrganization?.location,
          ...d.data(),
        }))
      }

      return null
    } catch (error) {
      console.error("Error getting user medical data:", error)
      return null // O manejar el error de alguna otra forma si es necesario
    }
  }

  const filterByDate = (data, days) => {
    const today = new Date()
    const itemDate = data.createdAt ? new Date(data.createdAt) : new Date()
    const diffInDays = Math.floor((today - itemDate) / (1000 * 3600 * 24))
    return diffInDays <= days
  }

  const getStats = async (agentsData) => {
    const statsData = {
      diagnostics: [],
      causes: [],
      totalIncapacities: 0,
      totalDaysOff: 0,
      preempleo: 0,
      preempleoByDay: {},
    }

    const diagnosisMap = {}
    const causeMap = {}

    const usersMedicalData = await Promise.all(
      agentsData.map(async (agent) => {
        const { email } = agent
        const userMedicalData = await getUserMedicalData(email)
        return userMedicalData
      })
    )

    usersMedicalData.forEach((userMedicalDataArray) => {
      if (userMedicalDataArray) {
        let filteredData = userMedicalDataArray

        // Filtrar por médico, ubicación y departamento si se especifican
        if (filterByDoctor) {
          filteredData = userMedicalDataArray.filter(
            (data) => data?.createdByEmail === filterByDoctor
          )
        }

        if (locationFilter) {
          filteredData = userMedicalDataArray.filter(
            (data) => data?.location === locationFilter
          )
        }

        if (departmentFilter) {
          filteredData = userMedicalDataArray.filter(
            (data) => data?.department === departmentFilter
          )
        }

        if (filteredData.length === 0) return

        const filteredDataByDays = filteredData.filter((data) =>
          filterByDate(data, filterDays)
        )

        filteredDataByDays.forEach((data) => {
          const isPreempleo =
            (data.diagnosis?.label?.toLowerCase() || "").includes(
              "preempleo"
            ) ||
            (data.cause?.label?.toLowerCase() || "").includes("preempleo") ||
            (data.userEmail?.toLowerCase() || "").includes("preempleo")

          if (isPreempleo) {
            statsData.preempleo += 1

            const createdAtDate = new Date(data.createdAt)
            const dateKey = createdAtDate.toISOString().split("T")[0]

            if (!statsData.preempleoByDay[dateKey]) {
              statsData.preempleoByDay[dateKey] = 0
            }
            statsData.preempleoByDay[dateKey] += 1

            return
          }

          // Contabilizar diagnósticos
          if (data.diagnosis && data.diagnosis.label) {
            const diagnosisLabel = data.diagnosis.label
            if (!diagnosisMap[diagnosisLabel]) {
              diagnosisMap[diagnosisLabel] = { count: 0, disabilityDays: 0 }
            }
            diagnosisMap[diagnosisLabel].count += 1
            if (data.patientNeedsDisability) {
              diagnosisMap[diagnosisLabel].disabilityDays +=
                data.patientDisabilityDays || 0
            }
          }

          // Contabilizar causas
          if (data.cause && data.cause.label) {
            const causeLabel = data.cause.label
            if (!causeMap[causeLabel]) {
              causeMap[causeLabel] = { count: 0, disabilityDays: 0 }
            }
            causeMap[causeLabel].count += 1
            if (data.patientNeedsDisability) {
              causeMap[causeLabel].disabilityDays +=
                data.patientDisabilityDays || 0
            }
          }

          // Contabilizar incapacidades generales
          if (data.patientNeedsDisability) {
            statsData.totalIncapacities += 1
            statsData.totalDaysOff += data.patientDisabilityDays || 0
          }
        })
      }
    })

    statsData.diagnostics = Object.entries(diagnosisMap).map(
      ([name, { count, disabilityDays }]) => ({
        name,
        value: count,
        disabilityDays,
      })
    )

    statsData.causes = Object.entries(causeMap).map(
      ([name, { count, disabilityDays }]) => ({
        name,
        value: count,
        disabilityDays,
      })
    )

    return statsData
  }

  const getDeparmentsByLocation = async (locationId) => {
    const depts = await firebase.getOrgDepartmentByLocation(orgId, locationId)
    setDepartmentsList(depts)
  }

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true)
      const agentsDataFromApi = agents?.map((doc) => doc.data())
      const doctorsDataFromApi = doctors?.map((doc) => doc.data())
      setDoctorsData(doctorsDataFromApi)
      const statsData = await getStats(agentsDataFromApi)
      setIsLoading(false)
      setStats(statsData)
    }

    fetchStats()
  }, [
    agents,
    doctors,
    filterDays,
    filterByDoctor,
    locationFilter,
    departmentFilter,
  ])

  useEffect(() => {
    if (orgId) {
      const getForm = async () => {
        const orgLocations = await firebase.getOrgLocationList(orgId)
        setOrgLocationList(orgLocations)
      }
      getForm()
    }
  }, [orgId])

  useEffect(() => {
    if (locationFilter) {
      const fetchData = async () => {
        await getDeparmentsByLocation(locationFilter)
      }
      fetchData()
    }
  }, [locationFilter])

  const exportToCSV = () => {
    setButtonCsvLoading(true)
    const diagnosticsData = stats.diagnostics.map((diagnostic) => ({
      Diagnóstico: diagnostic.name,
      Cantidad: diagnostic.value,
      DíasDeIncapacidad: diagnostic.disabilityDays || 0,
    }))

    const causesData = stats.causes.map((cause) => ({
      Causa: cause.name,
      Cantidad: cause.value,
      DíasDeIncapacidad: cause.disabilityDays || 0,
    }))

    const formatToCSV = (data) => {
      const header = Object.keys(data[0])
      const rows = data.map((row) =>
        header.map((field) => row[field]).join(",")
      )
      return [header.join(","), ...rows].join("\n")
    }

    const diagnosticsCSV = formatToCSV(diagnosticsData)
    const causesCSV = formatToCSV(causesData)
    const date = new Date()
    const timeRange = timeOptions?.find(
      (option) => option?.value === filterDays
    )

    const totals = [
      ["Total Preempleos", stats.preempleo],
      ["Total Incapacidades", stats.totalIncapacities],
      ["Total Días de Incapacidad", stats.totalDaysOff],
    ]

    const totalsCSV = totals.map((row) => row.join(",")).join("\n")

    const combinedCSV = `Fecha:\n${date.toLocaleDateString()}\n\nRango de Tiempo:\n${
      timeRange?.label
    } \n\nTotales:\n${totalsCSV}\n\nDiagnósticos:\n${diagnosticsCSV}\n\nCausas:\n${causesCSV}`

    const blob = new Blob([combinedCSV], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `Estadísticas.${date.toLocaleDateString()}.csv`
    link.click()
    URL.revokeObjectURL(url)
    setButtonCsvLoading(false)
  }

  const exportToPDF = async () => {
    setButtonPdfLoading(true)
    // eslint-disable-next-line new-cap
    const doc = new jsPDF()

    const margin = 20
    const yInitial = 20
    let yPosition = yInitial
    const chartWidth = 180
    const chartHeight = 100
    const maxPageHeight = 280

    const date = new Date()
    const timeRange = timeOptions?.find(
      (option) => option?.value === filterDays
    )

    doc.setFontSize(18)
    doc.text("Estadísticas de Incapacidades", margin, yPosition)
    yPosition += 10

    doc.setFontSize(12)
    doc.text(`Rango de Tiempo: ${timeRange?.label}`, margin, yPosition)
    yPosition += 10

    doc.setFontSize(12)
    doc.text(`Total Preempleos: ${stats.preempleo}`, margin, yPosition)
    yPosition += 10

    doc.setFontSize(12)
    doc.text(
      `Total Incapacidades: ${stats.totalIncapacities}`,
      margin,
      yPosition
    )
    yPosition += 10
    doc.text(
      `Total Días de Incapacidad: ${stats.totalDaysOff}`,
      margin,
      yPosition
    )
    yPosition += 20

    const captureAndAddChart = (chartId) => {
      return new Promise((resolve) => {
        const chartElement = document.getElementById(chartId)
        if (chartElement) {
          html2canvas(chartElement, {
            scale: 4,
            logging: false,
            useCORS: true,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png")
            if (yPosition + chartHeight > maxPageHeight) {
              doc.addPage()
              yPosition = margin
            }
            doc.addImage(
              imgData,
              "PNG",
              margin,
              yPosition,
              chartWidth,
              chartHeight
            )
            resolve(yPosition + chartHeight + 20)
          })
        } else {
          resolve(yPosition)
        }
      })
    }

    yPosition = await captureAndAddChart("diagnostic-chart", yPosition)
    yPosition = await captureAndAddChart("cause-chart", yPosition)
    yPosition = await captureAndAddChart("incapacity-chart", yPosition)

    doc.save(`Estadisticas_${date.toLocaleDateString()}.pdf`)
    setButtonPdfLoading(false)
  }

  const customLegendDiagnostic = (props) => {
    const { payload } = props
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {payload.map((entry) => (
          <div
            key={entry.value}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: entry.payload.fill,
                marginRight: "5px",
              }}
            />
            <span>
              {entry.value === "value"
                ? "Cantidad de Diagnósticos"
                : "Días de Incapacidad por diagnóstico"}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const customLegendCause = (props) => {
    const { payload } = props
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {payload.map((entry) => (
          <div
            key={entry.value}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: entry.payload.fill,
                marginRight: "5px",
              }}
            />
            <span>
              {entry.value === "value"
                ? "Cantidad de Causas"
                : "Días de Incapacidad por causa"}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const customLegendDisabilityDays = (props) => {
    const { payload } = props
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {payload.map((entry) => (
          <div
            key={entry.value}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: entry.color,
                marginRight: "5px",
              }}
            />
            <span>
              {entry.value === "value" ? "Diagnósticos" : "Días de Incapacidad"}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { value } = payload[0]
      const { name } = payload[0]
      const { name: disabilityName } = payload[1]
      const { value: disabilityValue } = payload[1]

      let customText = ""
      let disabilityDaysText = ""
      if (name === "value") {
        customText = `${value}`
      } else {
        customText = `${value}`
      }

      if (disabilityName === "disabilityDays") {
        disabilityDaysText = `${disabilityValue}`
      }

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p
            className="label"
            style={{ fontWeight: "bold" }}
          >{`${label}: ${customText}`}</p>
          {disabilityDaysText && (
            <p
              className="label"
              style={{ fontWeight: "bold" }}
            >{`Días de Incapacidad : ${disabilityDaysText}`}</p>
          )}
        </div>
      )
    }

    return null
  }

  const doctorsSelect = doctorsData?.map((dr) => ({
    value: dr?.email,
    label: dr?.displayName,
  }))

  const orgLocationsSelect = orgLocationList?.map((locationValue) => ({
    value: locationValue?.id,
    label: locationValue?.displayName,
  }))

  const orgDepartmentsSelect = departmentsList?.map((departmentValue) => ({
    value: departmentValue?.id,
    label: departmentValue?.displayName,
  }))

  const chartData = stats
    ? Object.entries(stats.preempleoByDay)
        .map(([date, preempleos]) => ({
          date,
          preempleos,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date))
    : []
  return (
    <>
      <Nav titleKey="orgs_statistics" onFilter={null} />
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <>
            {stats && (
              <StatsContainer>
                <RowContainer
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ marginRight: "50px" }}>
                    <ButtonWithLoader
                      onClick={() => exportToCSV()}
                      isLoading={isButtonCsvLoading}
                      disabled={isButtonPdfLoading}
                    >
                      Exportar a CSV
                    </ButtonWithLoader>
                  </div>

                  <ButtonWithLoader
                    onClick={() => exportToPDF()}
                    isLoading={isButtonPdfLoading}
                    disabled={isButtonCsvLoading}
                  >
                    Exportar a PDF
                  </ButtonWithLoader>
                </RowContainer>

                <div style={{ marginTop: 50 }}>
                  <Text>Filtros</Text>
                  <FiltersContainer>
                    <div style={{ width: 300, marginRight: 50 }}>
                      <CustomSelect
                        value={timeOptions?.find(
                          (option) => option?.value === filterDays
                        )}
                        formKey="daysFilter"
                        lngKey="days_label"
                        handleChange={(event, value) => {
                          setFilterDays(value)
                        }}
                        items={timeOptions}
                      />
                    </div>

                    <div style={{ width: 300, marginRight: 50 }}>
                      <CustomSelect
                        value={doctorsSelect?.find(
                          (option) => option?.value === filterByDoctor
                        )}
                        formKey="doctorsFilter"
                        lngKey="select_doctor"
                        handleChange={(event, value) => {
                          setDoctorFilter(value)
                        }}
                        items={doctorsSelect}
                      />
                    </div>

                    <div style={{ width: 300, marginRight: 50 }}>
                      <CustomSelect
                        value={orgLocationsSelect?.find(
                          (option) => option?.value === locationFilter
                        )}
                        formKey="locationFilter"
                        lngKey="select_location"
                        handleChange={(event, value) => {
                          setLocationFilter(value)
                        }}
                        items={orgLocationsSelect}
                      />
                    </div>

                    <div style={{ width: 300 }}>
                      <CustomSelect
                        value={orgDepartmentsSelect?.find(
                          (option) => option?.value === departmentFilter
                        )}
                        formKey="departmentFilter"
                        lngKey="select_department"
                        handleChange={(event, value) => {
                          setDepartmentFilter(value)
                        }}
                        items={orgDepartmentsSelect}
                        isDisabled={!locationFilter}
                      />
                    </div>
                  </FiltersContainer>
                </div>

                <div>
                  <div id="diagnostic-chart" style={{ marginTop: "50px" }}>
                    <Text>Gráfico de Diagnósticos</Text>
                    {stats.diagnostics && stats.diagnostics.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={stats.diagnostics}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip
                            content={CustomTooltip}
                            contentStyle={{
                              backgroundColor: "#fff",
                              borderColor: "#ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                            labelStyle={{ fontWeight: "bold" }}
                          />
                          <Legend content={customLegendDiagnostic} />
                          <Bar
                            dataKey="value"
                            stackId="a"
                            fill="#1E22AA"
                            label={({ value }) => `${value} cantidad`}
                            animationDuration={500}
                          />
                          <Bar
                            dataKey="disabilityDays"
                            stackId="a"
                            fill="#D93240"
                            label={({ value }) =>
                              `${value} días de incapacidad`
                            }
                            animationDuration={500}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <EmptyMessageStyles>
                        <SadTear size="24" />
                        <Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          No hay datos de diagnósticos disponibles.
                        </Text>
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "#777",
                            marginTop: "5px",
                          }}
                        >
                          Parece que no se han registrado diagnósticos para este
                          período. Intenta aplicar otros filtros o verifica más
                          tarde.
                        </Text>
                      </EmptyMessageStyles>
                    )}
                  </div>

                  <div id="cause-chart" style={{ marginTop: "100px" }}>
                    <Text>Gráfico de Causas</Text>
                    {stats.causes && stats.causes.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={stats.causes}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip
                            content={CustomTooltip}
                            contentStyle={{
                              backgroundColor: "#fff",
                              borderColor: "#ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                            labelStyle={{ fontWeight: "bold" }}
                          />
                          <Legend content={customLegendCause} />
                          <Bar
                            dataKey="value"
                            stackId="a"
                            fill="#1E22AA"
                            label={({ value }) => `${value} cantidad`}
                            animationDuration={500}
                          />
                          <Bar
                            dataKey="disabilityDays"
                            stackId="a"
                            fill="#D93240"
                            label={({ value }) =>
                              `${value} días de incapacidad`
                            }
                            animationDuration={500}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <EmptyMessageStyles>
                        <SadTear size="24" />
                        <Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          No hay datos de causas disponibles.
                        </Text>
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "#777",
                            marginTop: "5px",
                          }}
                        >
                          Parece que no se han registrado causas para este
                          período. Intenta aplicar otros filtros o verifica más
                          tarde.
                        </Text>
                      </EmptyMessageStyles>
                    )}
                  </div>

                  <div id="incapacity-chart" style={{ marginTop: "100px" }}>
                    <Text>
                      Gráfico Combinado: Incapacidades y Días de Incapacidad
                    </Text>
                    {stats.diagnostics && stats.diagnostics.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={stats.diagnostics}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <YAxis yAxisId="right" orientation="right" />
                          <Tooltip
                            content={CustomTooltip}
                            contentStyle={{
                              backgroundColor: "#fff",
                              borderColor: "#ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                            labelStyle={{ fontWeight: "bold" }}
                          />
                          <Legend content={customLegendDisabilityDays} />
                          <Bar
                            dataKey="value"
                            barSize={20}
                            fill="#1E22AA"
                            animationDuration={500}
                          />
                          <Line
                            type="monotone"
                            dataKey="disabilityDays"
                            stroke="#D93240"
                            dot={false}
                            yAxisId="right"
                            animationDuration={500}
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    ) : (
                      <EmptyMessageStyles>
                        <SadTear size="24" />
                        <Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          No hay datos de incapacidades disponibles.
                        </Text>
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "#777",
                            marginTop: "5px",
                          }}
                        >
                          Parece que no se han registrado incapacidades para
                          este período. Intenta aplicar otros filtros o verifica
                          más tarde.
                        </Text>
                      </EmptyMessageStyles>
                    )}
                  </div>

                  <div id="preempleo-chart" style={{ marginTop: "100px" }}>
                    <Text>Gráfico de Preempleo</Text>
                    {chartData && chartData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={chartData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="preempleos" fill="#82ca9d" />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <EmptyMessageStyles>
                        <SadTear size="24" />
                        <Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          No hay datos de preempleos disponibles.
                        </Text>
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "#777",
                            marginTop: "5px",
                          }}
                        >
                          Parece que no se han registrado preempleos para este
                          período. Intenta aplicar otros filtros o verifica más
                          tarde.
                        </Text>
                      </EmptyMessageStyles>
                    )}
                  </div>
                </div>
              </StatsContainer>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  overflow: auto;
`

export const Text = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const CenteredText = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
    margin-top: 100px;
    margin-bottom: 100px;
  `
)

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
`

export const Button = styled.button`
  height: 40px;
  width: 120px;
  border-radius: 5px;
  background-color: #4d66f5;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
`
export const TooltipText = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const ClipboardContainer = styled.div`
  margin-left: 20px;
  cursor: pointer;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StatsContainer = styled.div`
  width: 90%;
  height: 100%;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 100px;
`

export const EmptyMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  color: "#D93240";
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

OrgStatistics.propTypes = {
  firebase: PropTypes.shape().isRequired,
  doctors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orgId: PropTypes.string.isRequired,
}

export default withFirebase(OrgStatistics)
