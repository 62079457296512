import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { useSpring, animated } from "react-spring"
import { Add } from "@styled-icons/material/Add"
import { Login } from "@styled-icons/material/Login"
import { Close } from "@styled-icons/material/Close"
import { Save } from "@styled-icons/material/Save"
import { Lock } from "@styled-icons/material/Lock"
import { List } from "@styled-icons/material/List"

// Loader
const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: ${loaderAnimation} 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
`

const Button = styled.button`
  border: none;
  padding: 1rem 2rem;
  font-size: 16px;
  color: ${(props) => props.theme.color.white.lessdark};
  font-weight: 600;
  border-radius: 6px;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.color.secondary};
  min-width: ${(props) => (props.small ? "100px" : "250px")};
  transition: background-color 0.5s ease-in;
  cursor: pointer;

  &:disabled {
    background-color: ${(props) => props.theme.color.semiPalePurple};
    cursor: not-allowed;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 10px;
  }
  color: white;
`

const ButtonWithLoader = ({
  isLoading,
  iconName,
  children,
  disabled,
  ...props
}) => {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    let timeout = null
    if (isLoading) {
      setShowLoader(true)
    }

    if (!isLoading && showLoader) {
      timeout = setTimeout(() => {
        setShowLoader(false)
      }, 400)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isLoading, showLoader])

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height)
    }
  }, [children])

  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 })
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 })

  return (
    <Button
      {...props}
      ref={ref}
      style={
        showLoader
          ? {
              width: `${width}px`,
              height: `${height}px`,
            }
          : {}
      }
      disabled={showLoader || disabled}
    >
      {showLoader ? (
        <animated.div style={fadeOutProps}>
          <Loader />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>
          <ButtonContent>
            {iconName && iconName === "add" && <Add size="22" />}
            {iconName && iconName === "login" && <Login size="22" />}
            {iconName && iconName === "close" && <Close size="22" />}
            {iconName && iconName === "save" && <Save size="22" />}
            {iconName && iconName === "password" && <Lock size="22" />}
            {iconName && iconName === "list" && <List size="22" />}
            {children}
          </ButtonContent>
        </animated.div>
      )}
    </Button>
  )
}

ButtonWithLoader.defaultProps = {
  iconName: null,
  disabled: false,
}

ButtonWithLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  iconName: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

export default ButtonWithLoader
