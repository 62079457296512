/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import get from "lodash.get"

// Icons
import { Security } from "@styled-icons/material/Security"
import { Block } from "@styled-icons/material/Block"

// Components
import Tab from "../../components/atoms/Tab"
import PersonalInfo from "../../components/organisms/PersonalInfo"
import BackIcon from "../../components/atoms/BackIcon"
import CustomModal from "../../components/atoms/CustomModal"
import DepartmentDrawer from "../../components/molecules/DepartmentDrawer"
import SubmitButton from "../../components/atoms/SubmitButton"
import Loading from "../../components/atoms/Loading"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
} from "../../components/atoms/Table"

import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  ModalContainer,
  Description,
} from "../../components/atoms/ModalElements"

// Utils
import { withAuthorization } from "../../context/Session"
import { withFirebase } from "../../utils/Firebase"

const LocationDetail = ({ firebase }) => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [profileForm, setProfileForm] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isShowDeptoModalOpen, setIsShowDeptoModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [currentLocation, setCurrentLocation] = useState(
    get(state, ["currentUser"])
  )
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [departaments, setDepartaments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleAddDepartment = async (saved) => {
    if (openDrawerCreate) {
      if (saved) {
        const deptos = await firebase.getOrgDepartmentByLocation(
          state.orgId,
          currentLocation.id
        )
        setDepartaments(deptos)
      }
    }

    setOpenDrawerCreate(!openDrawerCreate)
  }

  const handleShowDepartment = () => {
    setIsShowDeptoModalOpen(!isShowDeptoModalOpen)
  }

  useEffect(() => {
    const getForms = async () => {
      const profile = await firebase.getOrgLocation(
        state.orgId,
        currentLocation.id
      )

      setProfileForm({
        ...profile.data(),
        id: currentLocation.id,
      })
    }
    if (firebase && Object.keys(currentLocation).length > 0) {
      getForms()
    }
  }, [firebase, currentLocation, state.orgId])

  useEffect(() => {
    const getData = async () => {
      const deptos = await firebase.getOrgDepartmentByLocation(
        state.orgId,
        currentLocation.id
      )
      setDepartaments(deptos)
    }
    if (firebase && Object.keys(currentLocation).length > 0) {
      getData()
    }
  }, [firebase, currentLocation, state.orgId])

  const handleUserStatus = async (status) => {
    await firebase.saveAgentData({
      orgId: state.orgId,
      id: currentLocation.id,
      data: {
        active: status,
        status: status ? "active" : "inactive",
      },
    })
    setCurrentLocation((prevState) => {
      return {
        ...prevState,
        active: status,
        status: status ? "active" : "inactive",
      }
    })
    setIsModalOpen(false)
  }

  const removeDepto = async (deptoId) => {
    await firebase.deleteOrgDepartment({
      orgId: state.orgId,
      locationId: currentLocation.id,
      departmentId: deptoId,
    })

    const deptos = await firebase.getOrgDepartmentByLocation(
      state.orgId,
      currentLocation.id
    )
    setDepartaments(deptos)

    setIsShowDeptoModalOpen(false)

    addToast(t("saved_successfully"), {
      appearance: "success",
    })
  }

  const getModalTitle = () => {
    return get(currentLocation, ["status"]) === "active"
      ? t("block_user")
      : t("enabled_user")
  }

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          {get(currentLocation, ["displayName"], "Back")}
        </Back>
        {get(currentLocation, ["status"]) === "inactive" && (
          <Badge onClick={() => setIsModalOpen(true)}>
            <span>
              <Security size="18" />
              {t("enabled_user")}
            </span>
          </Badge>
        )}
        {get(currentLocation, ["status"]) === "active" && (
          <Badge isBlock onClick={() => setIsModalOpen(true)}>
            <span>
              <Block size="18" />
              {t("block_user")}
            </span>
          </Badge>
        )}
      </Nav>

      <Container>
        <TabsContainer>
          <Tab
            textKey="users_profile"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
        </TabsContainer>
        <>
          <SubmitButton
            iconName="add"
            // isLoading={isLoading}
            onClick={handleAddDepartment}
            labelName={t("create_location_department")}
          />

          <SubmitButton
            iconName="list"
            // isLoading={isLoading}
            onClick={handleShowDepartment}
            labelName={t("show_location_department")}
          />
        </>
        {activeTab === 0 && Object.keys(profileForm).length > 0 && (
          <PersonalInfo user={profileForm} isLocation />
        )}
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <Title>{getModalTitle()}</Title>
                <Description>
                  {get(currentLocation, ["status"]) === "active"
                    ? t("block_confirmation", {
                        user: get(currentLocation, ["displayName"], ""),
                      })
                    : t("verify_confirmation", {
                        user: get(currentLocation, ["displayName"], ""),
                      })}
                  {}
                </Description>
                <ButtonsContainer>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleUserStatus(
                        get(currentLocation, ["status"]) === "inactive"
                      )
                    }
                  >
                    {t("confirm")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {isShowDeptoModalOpen && (
        <CustomModal
          modalOpen={isShowDeptoModalOpen}
          setModalOpen={setIsShowDeptoModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions w="900px" h="500px">
              <section>
                <Title m="8px">{t("department_name_label")}</Title>
                <ModalContainer>
                  <TableContainer>
                    {isLoading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <>
                        <List>
                          <Table>
                            <TableHeader minWidth="1200px">
                              <TableHeaderItem>Nombre</TableHeaderItem>
                              <TableHeaderItem>Acción</TableHeaderItem>
                            </TableHeader>
                            {departaments.length > 0 ? (
                              departaments.map((depto) => (
                                <>
                                  <TableRow
                                    key={depto.id}
                                    isVerticalOnMobile
                                    minWidth="1200px"
                                  >
                                    <TableRowItem>
                                      {depto?.displayName}
                                    </TableRowItem>
                                    <TableRowItem>
                                      <Button
                                        style={{ cursor: "pointer" }}
                                        type="button"
                                        onClick={() => removeDepto(depto.id)}
                                      >
                                        Eliminar
                                      </Button>
                                    </TableRowItem>
                                  </TableRow>
                                </>
                              ))
                            ) : (
                              <EmptyOrLoadingContainer>
                                <Text>
                                  En este momento no tiene departamentos.
                                </Text>
                              </EmptyOrLoadingContainer>
                            )}
                          </Table>
                        </List>
                      </>
                    )}
                  </TableContainer>
                </ModalContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      <DepartmentDrawer
        open={openDrawerCreate}
        closeDrawer={handleAddDepartment}
        orgId={state.orgId}
        locationId={currentLocation.id}
      />
    </div>
  )
}

const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

export const TableContainer = styled.div`
  height: 350px;
  padding: 20px;
  overflow: auto;
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  width: ${(props) => (props.isBlock ? "180px" : "160px")};
  height: 42px;
  background: rgba(77, 102, 245, 0.1);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
    }
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 150px);
  padding: 20px;
`

const List = styled.section`
  height: 100%;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  box-sizing: border-box;
`

export const Button = styled.button`
  height: 40px;
  width: 120px;
  border-radius: 5px;
  background-color: #4d66f5;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
`

export const Text = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

LocationDetail.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const ComposedDoctorDetail = compose(
  withFirebase,
  withAuthorization(condition)
)(LocationDetail)
export default ComposedDoctorDetail
