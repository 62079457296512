import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ButtonWithLoader from "../ButtonWithLoader"

const SubmitButton = ({
  iconName,
  isLoading,
  onClick,
  labelName,
  ...restProps
}) => {
  return (
    <Container
      {...restProps}
      iconName={iconName}
      onClick={onClick}
      isLoading={isLoading}
    >
      {labelName}
    </Container>
  )
}

const Container = styled(ButtonWithLoader)`
  width: 200px;
  margin-top: 20px;
  margin-right: 20px;
`

SubmitButton.defaultProps = {
  isLoading: false,
  iconName: "save",
}

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  labelName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  iconName: PropTypes.string,
}

export default SubmitButton
