/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import { withFirebase } from "../../../utils/Firebase"
import CustomInput from "../../atoms/CustomInput"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

const LocationDrawer = ({ firebase, open, closeDrawer, orgId }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_location")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            locationName: undefined,
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              await firebase.addOrgLocation({
                orgId,
                locationData: {
                  displayName: values.locationName,
                  active: true,
                },
              })

              addToast(t("saved_successfully"), {
                appearance: "success",
              })
              resetForm({ value: "" })
              setIsCreating(false)
              closeDrawer()
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            locationName: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>
                Ingrese los datos de la Sede que desea crear dentro de la
                organización.
              </p>

              <CustomInput
                touched={props.touched.locationName}
                errors={props.errors.locationName}
                icon="user"
                value={props.values.locationName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("location_name_label")}
                type="text"
                id="locationName"
                name="locationName"
              />

              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

LocationDrawer.defaultProps = {
  open: false,
}

LocationDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
}
export default withFirebase(LocationDrawer)
