import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Nav from "../../atoms/Nav"
import CardsContainer from "../../atoms/CardsContainer"
import UserCard from "../../molecules/UserCard"
import LocationDrawer from "../../molecules/LocationDrawer"
import RolesDrawer from "../../molecules/RolesDrawer"

const OrgLocations = ({
  locations,
  orgData,
  onUserCreated,
  orgName,
  deleteUser,
  isRemovingUser,
  country,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [openDrawerRoles, setOpenDrawerRoles] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleAddLocation = () => {
    if (openDrawerCreate) {
      // setLoadingOrgs(true)
      onUserCreated()
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }

  const handleAssignRoles = () => {
    if (openDrawerRoles) {
      setUserEmail("")
    }
    setOpenDrawerRoles(!openDrawerRoles)
  }

  return (
    <>
      <Nav
        titleKey="orgs_locations"
        onSearchChange={handleSearchChange}
        onFilter={null}
        onAddClick={handleAddLocation}
        searchValue={searchValue}
        addText="create_new"
      />
      <Container>
        <CardsContainer
          isEmpty={locations.length === 0}
          emptyMessage="Aun no tienes sedes dentro de la organización"
          fullHeight
        >
          {locations
            .filter((u) => {
              const data = u.data()
              if (searchValue) {
                return (
                  data.displayName &&
                  data.displayName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                )
              }
              return true
            })
            .map((usr) => {
              const data = usr.data()
              const userData = {
                ...data,
                id: usr.id,
                email: data.email,
                userEmail: data.email,
              }
              return (
                <UserCard
                  key={usr.id}
                  user={userData}
                  orgData={orgData}
                  deleteUser={deleteUser}
                  isRemovingUser={isRemovingUser}
                  setUserEmail={setUserEmail}
                  setOpenDrawerRoles={setOpenDrawerRoles}
                  isLocation
                  isFromOrgView
                />
              )
            })}
        </CardsContainer>
      </Container>
      <LocationDrawer
        open={openDrawerCreate}
        closeDrawer={handleAddLocation}
        orgId={orgData.uid}
        orgName={orgName}
        country={country}
      />
      <RolesDrawer
        open={openDrawerRoles}
        closeDrawer={handleAssignRoles}
        userEmail={userEmail}
      />
    </>
  )
}

export const Container = styled.div`
  height: calc(100% - 150px);
`

OrgLocations.defaultProps = {
  locations: [],
  deleteUser: false,
  isRemovingUser: () => {},
}

OrgLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape()),
  orgData: PropTypes.shape().isRequired,
  orgName: PropTypes.string.isRequired,
  onUserCreated: PropTypes.func.isRequired,
  deleteUser: PropTypes.func,
  isRemovingUser: PropTypes.bool,
  country: PropTypes.string.isRequired,
}

export default OrgLocations
