import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import get from "lodash.get"
import SignIn from "../../../pages/SignIn"
import Home from "../../../pages/Home"
import Dashboard from "../../../pages/Dashboard"
// import About from '../../../pages/About';
import Admin from "../../../pages/Admin"
import NotFound from "../../../pages/NotFound"
import Organizations from "../../../pages/Organizations"
import OrgsDetails from "../../../pages/OrgsDetails"
import Payments from "../../../pages/Payments"
import PayoutDetail from "../../../pages/PayoutDetail"
import PaymentsDetail from "../../../pages/PaymentDetail"
import PaymentsDetailOthers from "../../../pages/PaymentDetailOthers"
import ProductDetail from "../../../pages/ProductDetail"
import Users from "../../../pages/Users"
import UsersDetail from "../../../pages/UsersDetail"
import UsersForms from "../../../pages/UsersForms"
import AgentDetail from "../../../pages/AgentDetail"
import DoctorDetail from "../../../pages/DoctorDetail"
import LocationDetail from "../../../pages/LocationDetail"
import Search from "../../../pages/Search"
import Notifications from "../../../pages/Notifications"
import NewProduct from "../../../pages/NewProduct"
import Sessions from "../../../pages/SessionList"
import ForgotPasswordPage from "../../../pages/ForgotPassword"
import Layout from "../../organisms/Layout"
import * as ROUTES from "../../../utils/Constants/Routes"
import { AuthUserContext } from "../../../context/Session"

function Routes() {
  const authUser = useContext(AuthUserContext)
  return (
    <Router>
      <Route
        render={(routeProps) => (
          <Layout {...routeProps}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (get(authUser, ["roles", "BUSINESS_ADMIN"]))
                    return <Redirect to={ROUTES.ORGANIZATIONS} />
                  return <Redirect to={ROUTES.DASHBOARD} />
                }}
              />
              <Route exact path={ROUTES.HOME}>
                <Home />
              </Route>
              <Route default exact path={ROUTES.DASHBOARD}>
                <Dashboard />
              </Route>
              <Route path={ROUTES.SIGN_IN}>
                <SignIn />
              </Route>
              <Route path={ROUTES.ORGANIZATIONS}>
                <Organizations />
              </Route>
              <Route exact path={ROUTES.ORGS_DETAIL}>
                <OrgsDetails />
              </Route>
              <Route exact path={ROUTES.USERS}>
                <Users />
              </Route>
              <Route exact path={ROUTES.USERS_DETAIL}>
                <UsersDetail />
              </Route>
              <Route exact path={ROUTES.USERS_FORMS}>
                <UsersForms />
              </Route>
              <Route path={ROUTES.PAYMENTS}>
                <Payments />
              </Route>
              <Route exact path={ROUTES.PAYMENT_DETAIL}>
                <PaymentsDetail />
              </Route>
              <Route exact path={ROUTES.PAYMENT_DETAIL_OTHERS}>
                <PaymentsDetailOthers />
              </Route>
              <Route exact path={ROUTES.PAYOUT_DETAIL}>
                <PayoutDetail />
              </Route>
              <Route exact path={ROUTES.PRODUCT_DETAIL}>
                <ProductDetail />
              </Route>
              <Route exact path={ROUTES.ADD_PRODUCT}>
                <NewProduct />
              </Route>
              <Route path={ROUTES.ADMIN}>
                <Admin />
              </Route>
              <Route path={ROUTES.SEARCH}>
                <Search />
              </Route>
              <Route path={ROUTES.NOTIFICATIONS}>
                <Notifications />
              </Route>
              <Route exact path={ROUTES.AGENTS_DETAIL}>
                <AgentDetail />
              </Route>
              <Route exact path={ROUTES.DOCTORS_DETAIL}>
                <DoctorDetail />
              </Route>
              <Route exact path={ROUTES.LOCATIONS_DETAIL}>
                <LocationDetail />
              </Route>
              <Route exact path={ROUTES.SESSIONS}>
                <Sessions />
              </Route>
              <Route exact path={ROUTES.SESSIONS_DETAIL}>
                <Sessions />
              </Route>
              <Route path={ROUTES.FORGOT_PASSWORD}>
                <ForgotPasswordPage />
              </Route>
              <Route component={NotFound} />
              {/* <PrivateRoute exact path={ROUTES.ABOUT} component={About} /> */}
              {/* <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
            </Switch>
          </Layout>
        )}
      />
    </Router>
  )
}

export default Routes
